import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL;

class SettingService {

  async getSettings() {
    const config = {
      headers: authHeader() 
    };

    const response = await axios
      .get(`${API_URL}store-settings`, config);
      //console.log(response);
    return response.data;
  }

  async update(data) {
    const config = {
      headers: authHeader()
    };

    config.headers['Content-Type'] = 'multipart/form-data';

    const response = await axios
      .post(`${API_URL}store-settings`, data, config);
    return response.data;
  }

}

export default new SettingService();